import React from "react"

const Facebook = ({ fillColor }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <path
      d="M10.5 0.109863C4.69795 0.109863 0 4.81523 0 10.6099C0 16.4119 4.70536 21.1099 10.5 21.1099C16.302 21.1099 21 16.4045 21 10.6099C21 4.81523 16.302 0.109863 10.5 0.109863ZM13.9975 6.20831H12.9231C11.8634 6.20831 11.53 6.8678 11.53 7.54212V9.14268H13.9012L13.5233 11.6102H11.53V17.5605H8.86239V11.6102H6.68384V9.14268H8.85498V7.26794C8.85498 5.12645 10.1295 3.94084 12.0857 3.94084C13.0194 3.94084 13.9975 4.11127 13.9975 4.11127V6.20831Z"
      fill={fillColor || "#212121"}
    ></path>
  </svg>
)

export default Facebook
