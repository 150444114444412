import styled from "styled-components"

export const Link = styled.a``

export const Svg = styled.svg`
  fill: #fff;
  transition: fill 0.25s ease-out;
  &:focus,
  &:hover {
    fill: #999;
  }
`
