import React from "react"

const LinkedIn = ({ fillColor }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <path
      d="M10.75 0.109863C4.94795 0.109863 0.25 4.81523 0.25 10.6099C0.25 16.4119 4.95536 21.1099 10.75 21.1099C16.5446 21.1099 21.25 16.4119 21.25 10.6099C21.25 4.81523 16.552 0.109863 10.75 0.109863ZM7.89714 15.6783C7.09686 15.6783 6.30399 15.6783 5.49629 15.6783C5.49629 13.0774 5.49629 10.4839 5.49629 7.86816C6.30399 7.86816 7.08945 7.86816 7.89714 7.86816C7.89714 10.4765 7.89714 13.07 7.89714 15.6783ZM6.68931 6.77888C5.91867 6.77147 5.2814 6.11939 5.29622 5.34875C5.30363 4.57811 5.95572 3.94084 6.72636 3.95566C7.50441 3.97048 8.12685 4.60775 8.11944 5.37839C8.10462 6.14903 7.45995 6.78629 6.68931 6.77888ZM14.6329 15.6783C14.6329 15.6783 14.6329 12.3142 14.6032 11.1804C14.581 10.269 14.1512 9.81699 13.388 9.79476C12.5506 9.76512 11.9801 10.232 11.943 11.1286C11.8911 12.2771 11.8986 15.6783 11.8986 15.6783C11.8986 15.6783 10.0979 15.6783 9.47548 15.6783C9.47548 13.07 9.47548 10.4765 9.47548 7.86816C10.2461 7.86816 11.0019 7.86816 11.7874 7.86816C11.7874 8.21643 11.7874 8.5647 11.7874 8.98707C12.2616 8.26089 12.8396 7.8237 13.6177 7.69773C15.6554 7.37169 16.9003 8.37204 17.0189 10.5358C17.0337 10.847 17.0559 11.2916 17.0559 11.6102C17.0559 13.3664 17.0633 15.6783 17.0633 15.6783H14.6329Z"
      fill={fillColor || "#212121"}
    ></path>
  </svg>
)

export default LinkedIn
