import React from "react"

const YouTube = ({ fillColor }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <path
      d="M9.52637 12.6402L13.0313 10.6098L9.52637 8.58691V12.6402Z"
      fill={fillColor || "#212121"}
    ></path>
    <path
      d="M10.875 0.109863C5.07295 0.109863 0.375 4.81523 0.375 10.6099C0.375 16.4119 5.08036 21.1099 10.875 21.1099C16.6696 21.1099 21.375 16.4119 21.375 10.6099C21.375 4.81523 16.677 0.109863 10.875 0.109863ZM17.3439 13.8629C17.1883 14.4408 16.7289 14.9003 16.1509 15.0559C15.0987 15.3375 10.875 15.3375 10.875 15.3375C10.875 15.3375 6.6587 15.3375 5.59906 15.0559C5.02108 14.9003 4.56166 14.4408 4.40605 13.8629C4.12447 12.8106 4.12447 10.6173 4.12447 10.6173C4.12447 10.6173 4.12447 8.42391 4.40605 7.37168C4.56166 6.7937 5.02108 6.33428 5.59906 6.17867C6.65129 5.89709 10.875 5.89709 10.875 5.89709C10.875 5.89709 15.0913 5.89709 16.1509 6.17867C16.7289 6.33428 17.1883 6.7937 17.3439 7.37168C17.6255 8.42391 17.6255 10.6173 17.6255 10.6173C17.6255 10.6173 17.6255 12.8106 17.3439 13.8629Z"
      fill={fillColor || "#212121"}
    ></path>
  </svg>
)

export default YouTube
