import React from "react"

const Twitter = ({ fillColor }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <path
      d="M10.5025 0.109863C4.70046 0.109863 0.00250244 4.81523 0.00250244 10.6099C0.00250244 16.4119 4.70787 21.1099 10.5025 21.1099C16.2971 21.1099 21.0025 16.4119 21.0025 10.6099C21.0025 4.81523 16.3046 0.109863 10.5025 0.109863ZM16.3638 8.06082C16.3712 8.17938 16.3712 8.29794 16.3712 8.42391C16.3712 12.0993 13.5703 16.3452 8.44993 16.3452C6.879 16.3452 5.41923 15.8858 4.18175 15.0929C4.39665 15.1152 4.61895 15.13 4.84866 15.13C6.15282 15.13 7.35324 14.6854 8.30173 13.937C7.08648 13.9147 6.05649 13.107 5.70081 12.0029C5.87124 12.0326 6.04167 12.0548 6.22692 12.0548C6.47886 12.0548 6.72339 12.0178 6.96051 11.9585C5.68599 11.7065 4.7301 10.5802 4.7301 9.2316C4.7301 9.21678 4.7301 9.20937 4.7301 9.19455C5.10801 9.40203 5.53038 9.528 5.9898 9.54282C5.24139 9.04635 4.75233 8.1942 4.75233 7.22348C4.75233 6.71219 4.89312 6.23795 5.13024 5.82299C6.50109 7.50506 8.55367 8.61657 10.8656 8.72772C10.8211 8.52765 10.7915 8.31276 10.7915 8.09046C10.7915 6.55658 12.0364 5.30429 13.5777 5.30429C14.3779 5.30429 15.1041 5.64515 15.608 6.18608C16.2453 6.06011 16.8381 5.8304 17.3716 5.51177C17.1641 6.16385 16.7195 6.70478 16.1489 7.05305C16.7121 6.98636 17.2456 6.83816 17.7495 6.61586C17.379 7.17902 16.9048 7.66809 16.3638 8.06082Z"
      fill={fillColor || ""}
    ></path>
  </svg>
)

export default Twitter
