import React from "react"

const Instagram = ({ fillColor }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zm2.828-16.198c-.738-.034-.96-.04-2.828-.04-1.87 0-2.09.006-2.829.04-.682.031-1.053.145-1.3.241-.326.127-.56.278-.805.523a2.172 2.172 0 00-.523.805c-.096.247-.21.618-.241 1.3-.034.739-.04.96-.04 2.829 0 1.869.006 2.09.04 2.829.031.682.145 1.053.24 1.3.128.326.28.56.524.804.245.245.479.397.805.524.247.096.618.21 1.3.24.738.034.96.041 2.829.041 1.869 0 2.09-.007 2.828-.04.683-.032 1.053-.145 1.3-.241.327-.127.56-.279.805-.524s.396-.478.524-.805c.095-.246.21-.617.24-1.3.034-.738.041-.959.041-2.828 0-1.87-.007-2.09-.04-2.829-.032-.682-.146-1.053-.242-1.3a2.164 2.164 0 00-.523-.805 2.171 2.171 0 00-.805-.523c-.247-.096-.617-.21-1.3-.24zm-5.714-1.26c.746-.034.985-.042 2.886-.042 1.9 0 2.14.008 2.886.042.745.034 1.254.153 1.7.325.46.18.85.419 1.239.808.39.389.628.78.807 1.24.173.445.292.954.326 1.699.034.746.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886-.034.745-.152 1.254-.326 1.7-.179.46-.418.85-.807 1.239-.389.39-.78.628-1.24.807-.445.173-.954.292-1.699.326-.746.034-.985.042-2.886.042-1.901 0-2.14-.008-2.886-.042-.745-.034-1.254-.152-1.7-.326a3.431 3.431 0 01-1.239-.807 3.428 3.428 0 01-.807-1.24c-.173-.445-.292-.954-.326-1.699-.034-.747-.042-.985-.042-2.886 0-1.901.008-2.14.042-2.886.034-.745.153-1.254.326-1.7.178-.46.418-.85.807-1.239.389-.39.78-.628 1.24-.807.445-.173.954-.292 1.699-.326zM10.5 6.905a3.594 3.594 0 100 7.189 3.594 3.594 0 000-7.189zm0 5.928a2.333 2.333 0 110-4.666 2.333 2.333 0 010 4.666zm4.576-6.07a.84.84 0 11-1.68 0 .84.84 0 011.68 0z"
      fill={fillColor || "#212121"}
    ></path>
  </svg>
)

export default Instagram
